<template>
  <v-container>
    <v-row class="pt-3">
      <v-app-bar
        flat
        dense
        color="transparent"
      >
        <h1 class="text-h5 primary--text">Contato e endereço</h1>
      </v-app-bar>
      <v-row>
        <v-col
          cols="12"
          lg="12"
          align="right"
        >
          <v-btn
            width="400px"
            @click="saveData"
            class="primary mr-4"
            rounded
            dense
          >
            <v-icon
              class="ml-2 pr-2"
              small
              >fas fa-save</v-icon
            >
            Salvar
          </v-btn>
        </v-col>
      </v-row>
      <v-col
        cols="12"
        lg="12"
      >
        <v-row>
          <v-col
            cols="12"
            lg="4"
          >
            <v-text-field
              v-model="contacts.phone_number"
              label="Telefone"
              prepend-inner-icon="fas fa-phone"
              rounded
              v-mask="['(##) ####-####', '(##) #####-####']"
              outlined
              dense
            />
          </v-col>
          <v-col
            cols="12"
            lg="4"
          >
            <v-text-field
              v-model="contacts.wpp"
              label="Whatsapp"
              prepend-inner-icon="fab fa-whatsapp"
              v-mask="['(##) ####-####', '(##) #####-####']"
              rounded
              outlined
              dense
            />
          </v-col>
          <v-col
            cols="12"
            lg="4"
          >
            <v-text-field
              v-model="contacts.email"
              label="Email"
              prepend-inner-icon="fas fa-envelope"
              rounded
              outlined
              dense
            />
          </v-col>
          <v-col
            cols="12"
            lg="3"
          >
            <v-text-field
              v-model="contacts.cep"
              @click:append="getAddress"
              label="CEP"
              v-mask="'#####-###'"
              placeholder="xxxxx-xxx"
              append-icon="fas fa-search-location"
              outlined
              rounded
              dense
            />
          </v-col>
          <v-col
            cols="12"
            lg="2"
          >
            <v-autocomplete
              v-model="contacts.uf"
              label="UF"
              :items="uf != null ? uf : getUf()"
              item-text="sigla"
              item-value="sigla"
              outlined
              rounded
              dense
              :disabled="isDisabled"
              :loading="isLoading"
            />
          </v-col>
          <v-col
            cols="12"
            lg="5"
          >
            <v-text-field
              v-model="contacts.city"
              label="Cidade"
              rounded
              outlined
              dense
              :disabled="isDisabled"
              :loading="isLoading"
            />
          </v-col>
          <v-col
            cols="12"
            lg="2"
          >
            <v-text-field
              v-model="contacts.number"
              label="N°"
              v-mask="'########'"
              rounded
              outlined
              dense
              :disabled="isDisabled"
              :loading="isLoading"
            />
          </v-col>
          <v-col
            cols="12"
            lg="6"
          >
            <v-text-field
              v-model="contacts.address"
              label="Endereço"
              rounded
              outlined
              dense
              :disabled="isDisabled"
              :loading="isLoading"
            />
          </v-col>
          <v-col
            cols="12"
            lg="6"
          >
            <v-text-field
              v-model="contacts.district"
              label="Bairro"
              rounded
              outlined
              dense
              :disabled="isDisabled"
              :loading="isLoading"
            />
          </v-col>
        </v-row>

        <SectionTitle title="Mídias Sociais" />
        <v-row>
          <v-col
            cols="12"
            lg="4"
          >
            <v-text-field
              v-model="contacts.link_instagram"
              label="Instagram"
              prepend-inner-icon="fab fa-instagram"
              rounded
              outlined
              dense
            />
          </v-col>
          <v-col
            cols="12"
            lg="4"
          >
            <v-text-field
              v-model="contacts.link_facebook"
              label="Facebook"
              prepend-inner-icon="fab fa-facebook"
              rounded
              outlined
              dense
            />
          </v-col>
          <v-col
            cols="12"
            lg="4"
          >
            <v-text-field
              v-model="contacts.link_youtube"
              label="Youtube"
              prepend-inner-icon="fab fa-youtube"
              rounded
              outlined
              dense
            />
          </v-col>
          <v-col
            cols="12"
            lg="4"
          >
            <v-text-field
              v-model="contacts.link_twitter"
              label="Twitter"
              prepend-inner-icon="fab fa-twitter"
              rounded
              outlined
              dense
            />
          </v-col>
          <v-col
            cols="12"
            lg="4"
          >
            <v-text-field
              v-model="contacts.link_linkedin"
              label="Linkedin"
              prepend-inner-icon="fab fa-linkedin"
              rounded
              outlined
              dense
            />
          </v-col>
          <v-col
            cols="12"
            lg="4"
          >
            <v-text-field
              v-model="contacts.link_whatsapp"
              label="WhatsApp"
              prepend-inner-icon="fab fa-whatsapp"
              rounded
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'
export default {
  components: {
    SectionTitle: () => import('@/components/SectionTitle'),
  },
  mixins: [checkProfileRoutePermission],
  data() {
    return {
      contacts: null,
      uf: null,
      isDisabled: false,
      isLoading: false,
    }
  },
  computed: {
    currentAddress: {
      get: function () {
        return this.contacts
      },
      set: function (newValue) {
        this.$emit('setAddress', newValue)
      },
    },
  },
  methods: {
    getData() {
      this.$api
        .get('site/contact', {
          headers: {
            Authorization: `Bearer ${this.$cookies.get('token')}`,
          },
        })
        .then((res) => {
          this.contacts = res.data.data[0]
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getAddress() {
      this.isDisabled = true
      this.isLoading = true
      this.$api
        .get(`https://ws.apicep.com/cep.json?code=${this.currentAddress.cep}`, {
          timeout: 1000,
        })
        .then((res) => {
          let data = res.data
          this.setAddress(data)
        })
        .catch((err) => {
          if (err.code == 'ECONNABORTED') {
            this.$api
              .get(
                `https://cep.awesomeapi.com.br/json/${this.currentAddress.cep}`
              )
              .then((res) => {
                let data = res.data
                this.setAddress(data)
              })
          } else {
            console.log(err)
          }
        })
        .finally(() => {
          this.isDisabled = false
          this.isLoading = false
        })
    },
    getUf() {
      this.$api
        .get('https://servicodados.ibge.gov.br/api/v1/localidades/estados/')
        .then((res) => {
          this.uf = res.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    setAddress(data) {
      this.currentAddress.address = data.address
      this.currentAddress.district = data.district
      this.currentAddress.uf = data.state
      this.currentAddress.city = data.city

      this.$emit('setAddress', this.contacts)
    },
    saveData() {
      this.$api
        .post('site/contact/update', this.contacts)
        .then((res) => {
          this.$store.commit('setModal', {
            show: true,
            component: 'success',
            text: 'Dados atualizado com sucesso',
            confirm: () => {
              this.$store.commit('setModal', {
                show: false,
              })
            },
          })
          this.contacts = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
  created() {
    this.getData()
  },
}
</script>

<style></style>
